<template>
  <!-- 科室组件 -->
  <div class="register ">
    <div class="region">
      <!-- 日期选择栏 -->
      <van-tabs v-model="active" @change="time">
        <van-tab
          v-for="(item, index) in week"
          :title="item.date.substring(5, 10) + '\n' + item.weeks"
          :key="index"
        />
      </van-tabs>
      <!-- 搜索框 -->
      <div class="region-search">
        <van-search
          v-model="value"
          shape="round"
          placeholder="请输入搜索关键词"
          @input="inputMonitor"
          @clear="clearList"
        />
      </div>
      <Scroll ref="scroll" class="region-scroll">
        <div v-if="display == 'false'">
          <van-cell-group v-for="(item, index) in list" :key="index">
            <van-cell
              is-link
              :title="item.dept_name"
              @click="registerClick(item)"
            />
          </van-cell-group>
        </div>
        <div v-else-if="display == 'true'">
          <van-cell-group v-for="(item, index) in List" :key="index">
            <van-cell
              is-link
              :title="item.dept_name"
              @click="registerClick(item)"
            />
          </van-cell-group>
        </div>
        <van-empty v-else image="search" description="未搜索到此信息" />
      </Scroll>
    </div>
  </div>
</template>

<script>
//防抖函数
import Scroll from '@/components/content/scroll/BScroll'
import { Debounce } from 'common/utils'
import { departmentQuery } from 'network/service'
import { mapState } from 'vuex'
import { getWeek } from '@/common/utils'

export default {
  name: 'register',
  data() {
    return {
      //搜索关键字
      value: '',
      //搜索的一级科室和二级科室
      searchList: [],
      //科室数组
      list: [],
      //搜索后科室数组
      List: [],
      //一周时间
      week: [],
      //日期栏当前默认选择
      active: 0,
      display: '',
      activeNames: ['1'],
    }
  },
  components: {
    Scroll,
  },
  mounted() {
    // console.log(JUMP_POST)
    //科室请求
    this.oneWeek()
    this.registerPost()
    if (this.$route.query.search && this.$route.query.search.length != 0) {
      this.value = this.$route.query.search
      this.inputMonitor(this.value)
    }
  },
  watch: {
    //判断搜索是否为空
    searchList: function(newVal) {
      this.display = newVal.every((item, index, list) => {
        return list[index].length === 0
      })
    },
  },
  computed: {
    ...mapState(['seq', 'cardList']),
    dataList() {
      return this.searchList.length > 0 ? this.searchList : this.list
    },
  },
  methods: {
    //科室响应数据
    async registerPost(date) {
      let y = new Date().getFullYear()
      let m = new Date().getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = new Date().getDate()
      d = d < 10 ? '0' + d : d
      let DATE = y + '-' + m + '-' + d
      if (date == '当天') {
        date = y + '-' + m + '-' + d
      }
      if (date == '2021-12-30' || date == '2021-12-31') {
        this.list = []
        this.display = ''
        this.$toast('非常抱歉，12月30日和31日暂无法挂号和预约')
      } else if (
        (DATE == '2021-12-30' || DATE == '2021-12-31') &&
        date == undefined
      ) {
        this.list = []
        this.display = ''
        this.$toast('非常抱歉，12月30日和31日暂无法挂号和预约')
      } else {
        let res = await departmentQuery({
          treat_date: date == undefined ? DATE : date,
          shift: '0',
          treat_mode: '1',
          treat_type: '0',
        })
        //成功
        if (res.code == '0') {
          this.list = res.data.root.body.detail.filter(
            el =>
              el.dept_name != '眼科门诊' &&
              el.dept_name != '生长发育' &&
              el.dept_name != '儿保科康复门诊' &&
              el.dept_name != '儿科门诊（专家）' &&
              el.dept_name != '儿科急诊' &&
              el.dept_name != '儿科专家门诊' &&
              el.dept_name != '儿童眼保健门诊' &&
              el.dept_name != '儿童皮肤科' &&
              el.dept_name != '儿童外科急诊'
          )
          // let pediatricsArr = res.data.root.body.detail.filter(el => el.dept_name == "生长发育" || el.dept_name == "儿保科康复门诊")
          // this.list.push({
          //   dept_addr: "",
          //   dept_descript: "",
          //   dept_id: "",
          //   dept_name: "儿保科",
          //   order_id: "",
          //   reg_amt: "",
          //   reg_fee: "",
          //   rest_num: "",
          //   super_dept_id: "",
          //   super_dept_name: "",
          //   treat_fee: "",
          //   treat_type: "",
          //   childrenDept: []
          // })
          // this.list.forEach(el => {
          //   if (el.dept_name == '儿保科') {
          //     el.childrenDept = [...pediatricsArr, ...el.childrenDept]
          //     console.log(el.childrenDept)
          //   }
          // })
          this.display = 'false'
          this.list.sort((x, y) => {
            return x.dept_name.localeCompare(y.dept_name)
          })
        }
        //重新计算高度
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      }
    },
    //搜索信息
    inputMonitor: Debounce(function(value) {
      if (this.value.trim().length != 0) {
        this.list.forEach(el => {
          if (el.dept_name.search(value) != -1) {
            this.List.push(el)
          }
        })
        if (this.List.length === 0) {
          this.display = 'empty'
        } else {
          this.display = 'true'
        }
      } else {
        this.display = 'false'
      }
      this.$toast.clear()
      //重新计算高度
      this.collapseClick()
    }, 500),
    //清除搜索信息
    clearList() {
      this.List = []
      this.display = false
      this.collapseClick()
    },
    //科室跳转
    registerClick({ dept_id, dept_name }, Class) {
      if (dept_name == '新生儿门诊' && !this.isInTime()) {
        this.$toast({
          message: '出生超过3个月无法挂号新生儿门诊',
          duration: 2000,
        })
        return
      }
      let nowTime = new Date().getHours() + ':' + new Date().getMinutes()
      if (this.week[this.active].date == '当天') {
        if (nowTime.split(':')[0] == 17 && nowTime.split(':')[1] > 30) {
          this.$toast({
            message: '非常抱歉，当天下午5点半之后无法挂号',
            duration: 2000,
          })
        } else if (nowTime.split(':')[0] > 17) {
          this.$toast({
            message: '非常抱歉，当天下午5点半之后无法挂号',
            duration: 2000,
          })
        } else {
          this.$store.state.dept_id = dept_id
          this.$router.push({
            path: `/choice-doctor/${dept_name}`,
            query: {
              dept_name: `${dept_name}(${Class})`,
              flag: this.$route.query.flag,
              doc_rank: Class,
              order_date: this.week[this.active].date,
            },
          })
        }
      } else {
        this.$store.state.dept_id = dept_id
        this.$router.push({
          path: `/choice-doctor/${dept_name}`,
          query: {
            dept_name: `${dept_name}(${Class})`,
            flag: this.$route.query.flag,
            doc_rank: Class,
            order_date: this.week[this.active].date,
          },
        })
      }
    },
    //时间选择
    time() {
      if (
        this.week[this.active].date == '12-30' ||
        this.week[this.active].date == '12-30'
      ) {
        this.$toast('非常抱歉，12月30日和31日暂无法挂号和预约')
      } else {
        this.registerPost(this.week[this.active].date)
      }
    },
    //出生超过3个月
    isInTime() {
      const idCard = this.cardList[0].idNo
      const year = idCard.substr(6, 4)
      const month = parseInt(idCard.substr(10, 2), 10) - 1
      const day = idCard.substr(12, 2)

      const diff = new Date() - new Date(year, month, day)

      const diffInMonths = Math.ceil(diff / (1000 * 60 * 60 * 24 * 30))

      return diffInMonths > 3
    },
    //获取明天之后的一星期时间
    oneWeek() {
      for (let i = 1; i <= 7; i++) {
        let dateArray = getWeek(i).split('-')
        let date = new Date(
          dateArray[0],
          parseInt(dateArray[1] - 1),
          dateArray[2]
        )
        let week = '周' + '日一二三四五六'.charAt(date.getDay())
        this.week.push({ date: getWeek(i), weeks: week })
      }
      this.week.unshift({ date: '当天', weeks: '当天' })
    },
    //获取折叠高度
    collapseClick() {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 400)
    },
  },
}
</script>

<style lang="less" scoped>
.region-scroll {
  height: calc(100vh - 7.375rem);
  overflow: hidden;
  /deep/.van-collapse-item__content {
    padding: 0;
  }
}
.register {
  .list-region {
    .tabs-a,
    .tabs-b {
      margin-top: 1rem;
    }
  }
}
/deep/.van-tabs--line .van-tabs__wrap {
  height: 4rem;
}
/deep/.van-tab {
  white-space: pre-wrap;
  text-align: center;
  &:nth-child(1) {
    line-height: 0rem;
  }
}
</style>
