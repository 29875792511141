<template>
  <!--科室-->
  <div>
    <Type />
  </div>
</template>

<script>

import Type from '@/components/common/type/type'
// import LoadingComponent from '@/components/content/loading/loading'
// import ErrorComponent from '@/components/content/error/error'
// const AsyncComponent = () => ({
//   component: new Promise((reslove) => {
//     setTimeout(() => {
//       require(["@/components/common/title/Title"], reslove)
//     }, 1000)
//   }),
//   loading: LoadingComponent,
//   error: ErrorComponent,
//   // 展示加载时组件的延时时间。默认值是 200 (毫秒)
//   delay: 200,
//   // 如果提供了超时时间且组件加载也超时了，
//   // 则使用加载失败时使用的组件。默认值是：`Infinity`
//   timeout: 1000,
// })
export default {
  name: 'department',
  components: {
    Type,
  },
}
</script>

<style lang="less" scoped>
</style>